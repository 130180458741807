var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cv-container",attrs:{"id":"cvContainer"}},[_c('Header',{attrs:{"name":_vm.cvDatas[0].name,"jobTitle":_vm.cvDatas[0].jobTitle,"phoneNumber":_vm.cvDatas[0].phoneNumber,"bgColor":_vm.bgColor,"email":_vm.cvDatas[0].email}}),_c('div',{staticClass:"cv-container-main-top"},[_c('div',{staticClass:"cv-container-main-top-experiences-area"},[_c('Experiences',{attrs:{"experiences":_vm.getExperiences,"bgColor":_vm.bgColor,"cvDatas":_vm.cvDatas}})],1),_c('div',{staticClass:"cv-container-main-top-about-area"},[_c('About',{attrs:{"bgColor":_vm.bgColor,"about":_vm.cvDatas[0].describe}})],1)]),_c('div',{staticClass:"cv-container-main-bottom"},[_c('div',{staticClass:"cv-container-main-bottom-education-area"},[_c('Education',{attrs:{"highschool":_vm.cvDatas[0].highschool,"university":_vm.cvDatas[0].university,"department":_vm.cvDatas[0].department,"highschoolYear":_vm.cvDatas[0].highschoolYear,"universityYear":_vm.cvDatas[0].universityYear,"bgColor":_vm.bgColor}})],1),_c('div',{staticClass:"cv-container-main-bottom-skills-area"},[_c('Skills',{attrs:{"skills":[
                    _vm.cvDatas[0].skill1,
                    _vm.cvDatas[0].skill2,
                    _vm.cvDatas[0].skill3,
                    _vm.cvDatas[0].skill4,
                    _vm.cvDatas[0].skill5,
                    _vm.cvDatas[0].skill6,
                ],"bgColor":_vm.bgColor}})],1)]),_c('br'),_c('br'),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }